.glide__bullets {
  width: 50%;
  float: left;
}
.glide__track {
  width: 50%;
  float: left;
}
.grapgh-section img {
  height: 100%;
  object-fit: cover;
}
.glide__bullet {
  position: relative;
  display: flex;
  padding: 0;
  border: 0;
  color: #000;
  height: 100%;
  margin-right: 10px;
  margin-left: 10px;
  background-color: transparent;
  flex-direction: column;
}
.glide__bullet {
  text-align: left;
}

.glide {
  width: 100%;
  height: auto;
  margin-top: 30px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide__bullet:before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 1px;
  height: 100%;
  display: block;
  background-color: #fff;
  transition: transform 5s;
  border: 1px solid rgb(0 0 0 / 20%);
  opacity: 1;
  transform: translateX(0);
  top: 16px;
  z-index: 9;
}
.glide__bullet.glide__bullet--active:after {
  background-color: #000;
}
.glide__bullet.glide__bullet--active:before {
  border: 1px solid #000;
}

.glide__bullet.glide__bullet--active span {
  color: #000;
  font-size: 24px;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__bullet.last-bullet:before {
  height: 0px;
}
.glide-section .heading {
  font-size: 30px;
  text-align: left;
  margin-bottom: 20px;
}
.glide__slide img {
  width: 50%;
  height: 100%;
  object-fit: cover;
  margin: 0 auto;
}
.glide__bullet span {
  transition: 0.5s;
  color: rgb(0 0 0 / 60%);
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  text-transform: capitalize;
  font-family: "Outfit", sans-serif;
  padding-left: 20px;
  transition: 0.5s;
}
.glide__bullet--active:before {
  opacity: 1;
  transform: translateX(0);
}

.glide__bullet:after {
  content: "";
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #9b9b9b;
  position: absolute;
  top: 12px;
  left: -3px;
  z-index: 9;
}
.glide__bullet p {
  color: rgba(0, 0, 0, 0.7);
  font-family: "Outfit", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.4px;
  max-width: 480px;
  padding-left: 20px;
  opacity: 0;
  visibility: hidden;
}
.glide__bullet--active p {
  opacity: 1;
  visibility: visible;
}
.glide-vector {
  position: absolute;
  right: -28%;
  width: 50%;
}
.glide-section {
  position: relative;
}

.banner-section .banner-left {
  width: 30%;
  float: left;
  position: relative;
  z-index: 1;
}
.home-banner.banner-section .banner-content h1 {
  font-size: 50px;
  line-height: 1.2;
  text-align: left;
  color: #ff9b05;
  font-weight: 800;
}
.banner-section .banner-content p {
  color: #fff;
}
.home-banner.banner-section .google-img {
  padding-top: 60px;
}
.home-banner.banner-section .banner-right {
  width: 100%;
  float: none;
}
.home-banner.banner-section .banner-content {
  bottom: 30%;
}
.banner-section .banner-right {
  width: 70%;
  float: left;
}
.banner-section .tab-content > .tab-pane {
  display: none;
}
.banner-section .tab-content > .active {
  display: block;
}
.banner-section .card-header {
  background: transparent;
  border: 0px;
  padding: 20px 10px;
  padding-bottom: 10px;
  padding-top: 15px;
}

.banner-section .nav-tabs {
  border: 0px;
  justify-content: center;
}
.banner-section .nav-tabs .nav-link {
  border-radius: 46px;
  background: #f2f2f2;
  display: inline-block;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  padding: 13px 43px;
  height: auto;
  color: #000;
  text-transform: uppercase;
  line-height: 1.2;
  border: 0px;
  font-family: "Outfit", sans-serif;
  text-align: center;
}
.banner-section .card {
  border-radius: 30px 30px 0px 0px;
  background: #fff;
  border: 0px;
  margin-top: -10px;
  padding-bottom: 0px;
  box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.12);
}
.banner-section .nav-tabs .nav-link.active {
  color: #fff;
  background: #fba505;
  position: relative;
  z-index: 9;
}
.banner-section .nav-tabs i {
  margin-right: 7px;
}
.banner-section .nav-item:last-child {
  margin-left: -34px;
}
.banner-section .nav-item {
  width: 50%;
}
.banner-section .nav-item:first-child {
  position: relative;
  z-index: 1;
}
.banner-section .form-control {
  font-size: 12px;
  border-radius: 0;
  background: #f2f2f2;
  border: 0px;
  padding: 22px 20px;
  font-family: "Outfit", sans-serif;
  color: #000;
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.banner-section .form-control:focus,
.banner-section .form-select:focus {
  box-shadow: 0 0 5pt 2pt #fba5055e;
  background: transparent;
}

.banner-section .form-select option {
  font-size: 13px;
  line-height: 40px;
}
.banner-section .form-group {
  margin-bottom: 11px;
  position: relative;
}

.banner-section .form-select {
  font-size: 12px;
  border-radius: 0;
  background: #f2f2f2;
  border: 0px;
  padding: 15px 20px;
  font-family: "Outfit", sans-serif;
  color: #000;
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.banner-section .input-group-addon {
  position: absolute;
  right: 6px;
  top: 5px;
  z-index: 1;
}

.banner-section .book-btn {
  margin-top: 0px;
}
.banner-section .book-btn,
.banner-section .theme-button {
  width: 100%;
}

.banner-section .theme-button {
  fill: #ff9b05;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.banner-section .offer-btn {
  margin-top: 56px;
}

.banner-section .date .input-group-addon:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  content: "\f073";
  color: #fba505;
  padding: 0 5px;
}
.banner-section .seat .input-group-addon,
.banner-section .cash .input-group-addon,
.banner-section .vehicle .input-group-addon {
  width: 19px;
  top: 13px;
  right: 9px;
}
.banner-section .vehicle .input-group-addon {
  width: 22px;
  top: 14px;
}

.banner-slider {
  position: relative;
  overflow-x: hidden;
}

.banner-section {
  min-height: 500px;
}

.banner-section .slider-col img {
  height: 100%;
  min-height: 500px;
}

.banner-section .content {
  font-weight: 600;
  color: rgb(0 0 0 / 60%);
  text-transform: capitalize;
  font-family: "Outfit", sans-serif;
  margin-left: 15px;
}
.banner-section .banner-content {
  position: absolute;
  bottom: 10%;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 86%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
}

.banner-section .form-control::placeholder {
  color: rgb(0 0 0 / 40%);
}
.banner-section .banner-content h1 {
  color: #fff;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
  text-align: left;
}
.banner-section .banner-content p {
  color: #fba505;
  font-size: 25px;
  font-weight: 900;
  line-height: 1.2;
  text-transform: capitalize;
  margin-bottom: 0px;
  text-align: left;
}
.banner-section.home-banner .banner-content p {
  color: #fff;
}
.banner-section .theme-button::before {
  background-color: #fba505;
}
.banner-section .theme-button:after {
  display: none;
}
.banner-section .theme-button span {
  border: 1px solid #fba505;
  /* text-transform: uppercase; */
}
.banner-section .banner-content span {
  display: block;
}
.banner-section .qr-image img {
  width: 170px;
}
.banner-section .google-img {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 30px;
}
.banner-section .google-img img {
  width: 120px;
  cursor: pointer;
  transition: 0.5s;
  border-radius: 10px;
}
.banner-section .qr-image {
  margin-right: 10px;
}
.banner-section .google-img li:hover img {
  transform: scale(1.1);
}
.banner-section .slick-dots li {
  height: 8px;
  width: 8px;
  margin: 0;
  margin-right: 8px;
}
.banner-section .slick-dots {
  text-align: center;
  bottom: 65px;
  margin: 0;
  cursor: auto;
  margin-left: 22px;
}
.banner-section .slick-dots button {
  display: block;
  width: 8px;
  height: 8px;
  padding: 0;
  border: 1px solid rgb(0 0 0 / 0%);
  border-radius: 100%;
  background-color: rgb(0 0 0 / 45%);
  margin: 0px !important;
  opacity: 1;
}
.banner-section .slick-dots li.slick-active {
  margin-right: 28px;
}
.banner-section .slick-dots li.slick-active button {
  background-color: #fff;
  border-color: #fff;
  opacity: 1;
  border-radius: 7px;
  width: 30px;
}
.banner-section .slick-dots li button:before {
  display: none;
}
.banner-section .bottom-section li {
  width: 50%;
  background: #f1f1f1;
  border: 2px solid #f1f1f1;
  float: left;
  cursor: pointer;
  min-height: 94px;
  transition: all 0.3s ease-in-out;
  transition: all 400ms cubic-bezier(0.2, 0.8, 0.4, 1);
  padding-top: 6px;
}

.banner-section .bottom-section li:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  transition: all 400ms cubic-bezier(0.2, 0.8, 0.4, 1);
  transform: scale(1.01);
}

.banner-section .bottom-section ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  width: 100%;
}
.banner-section .bottom-section {
  display: flex;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  list-style: none;
  width: 100%;
}
.banner-section .riding-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-section .icon {
  width: 93px;
  height: 77px;
}
.riding-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner-section .bottom-section li:hover .content {
  color: #000;
}
.chat-section {
  top: 45%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  right: -79px;
  position: fixed;
  z-index: 13;
  font-size: 18px;
  width: 178px;
  height: 65px;
  overflow: hidden;
  background: #000;
  color: #ffffff;
  text-align: center;
  transform: rotate(-90deg);
  transition: 0.5s;
  border: 1px solid #000;
}
.chat-section span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  font-size: 17px;
}
.chat-section img {
  width: 60px;
  margin-left: 9px;
}
.chat-section i {
  font-size: 13px;
  margin-left: 5px;
  line-height: 1.5;
}
.chat-section:hover {
  background: #fff;
  color: #000;
  border-radius: 10px;
  border: 1px solid #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
/* .chat-section:hover img{
    filter: invert(100%) sepia(24%) saturate(2168%) hue-rotate(143deg) brightness(101%) contrast(99%);
    } */
/* pickup slider */
.pickup-section.section {
  padding: 60px 0px;
  padding-bottom: 30px;
}
.pickup-col {
  position: relative;
}
.pickup-section .heading {
  position: absolute;
  top: 44%;
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
}
.pickup-section .heading h2 {
  color: #fff;
  font-size: 60px;
  font-style: normal;
  font-weight: 900;
  line-height: 1;
  margin-bottom: 0px;
  font-family: "Outfit", sans-serif;
}
.banner-section .tab-content {
  position: relative;
  margin-left: 0px;
}
.image-style {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.image-style:before {
  content: "";
  background: linear-gradient(
    0deg,
    rgb(251 165 5 / 70%),
    rgba(0, 112, 186, 0.7)
  );
  height: 0;
  width: 0;
  z-index: 1;
  position: absolute;
  transition-duration: 1.3s;
  right: 0;
  opacity: 0.7;
  top: 0;
}
.image-style img {
  transition: transform 0.5s ease-in-out;
}
.image-style:after {
  content: "";
  background: linear-gradient(
    0deg,
    rgb(251 165 5 / 100%),
    rgba(0, 112, 186, 0.7)
  );
  height: 0;
  width: 0;
  z-index: 1;
  position: absolute;
  transition-duration: 1.3s;
  bottom: 0;
  opacity: 0.7;
  left: 0;
}

.image-style:hover::after,
.image-style:hover::before {
  height: 100%;
  opacity: 0;
  width: 100%;
}
.image-style:hover img {
  transform: scale(1.08);
}
.vert-move {
  -webkit-animation: mover 0.8s infinite alternate;
  animation: mover 0.8s infinite alternate;
}

.vert-move {
  -webkit-animation: mover 0.8s infinite alternate;
  animation: mover 0.8s infinite alternate;
}
.vert-move {
  -webkit-animation: mover 0.8s infinite alternate;
  animation: mover 0.8s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10px);
  }
}
@keyframes mover {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10px);
  }
}
.react-datepicker-popper {
  z-index: 9999999 !important;
}
.banner-section .tab-content:before {
  content: "";
  background: url("/public/assets/banner/status-bar.webp");
  width: 20px;
  height: 27%;
  display: block;
  position: absolute;
  background-repeat: no-repeat;
  top: 12px;
  left: -2px;
  background-size: contain;
}
/*Book Now*/
.ride-section .ride-inner {
  display: flex;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.ride-section .ride-left,
.ride-section .ride-right {
  width: 50%;
  float: left;
}
.ride-inner-wrap {
  padding: 50px;
}
.ride-inner-wrap h3 {
  color: #000;
  font-family: "Outfit", sans-serif;
  font-size: 27px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.1;
  text-transform: capitalize;
}
.ride-inner-wrap p {
  color: rgba(0, 0, 0, 0.7);
  font-family: "Outfit", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.4px;
}
/*Choose Us*/
.section {
  padding: 35px 0px;
}

.heading {
  color: #000;
  font-family: "Outfit", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1;
  text-transform: capitalize;
  text-align: center;
}
.commute-section .heading {
  font-size: 30px;
}
p {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.2;
  font-family: "Outfit", sans-serif;
}

.chosse-col {
  transition: all 0.3s ease-in;
  margin-right: 30px;
  margin-bottom: 10px;
}
.chosse-col:hover {
  transform: scale(1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 20px;
  cursor: pointer;
}
/* .chosse-col:hover .image{
    background:#FBA505;
    } */
/* Commute Togopool */
.commute-section .commute-col {
  width: 50%;
  float: left;
  padding: 0px;
}
.commute-inner {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 100%;
}
.commute-image,
.commute-content {
  width: 50%;
  float: left;
  height: 100%;
}

.commute-image {
  overflow: hidden;
}
.commute-image img {
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
}

.commute-image img:hover {
  transform: scale(1.08);
}
.commute-section .content {
  background: #000;
  padding: 20px;
  height: 100%;
  width: 100%;
  align-items: baseline;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.commute-section .content h5,
.commute-section .content h5 a {
  color: #fff;
  font-family: "Outfit", sans-serif;
  font-size: 29px;
  font-weight: 700;
  line-height: 1;
  text-transform: capitalize;
  text-decoration: none;
}
.commute-section .content h5 a:hover {
  color: #fba505;
}
.commute-section .content p {
  color: rgb(255 255 255 / 70%);
  font-family: "Outfit", sans-serif;
  font-weight: 400;
  line-height: 1.3;
}
/* Register Section  */
.revol-section .image-wrapper {
  /* background: url("/public/assets/commute_togopool/commute.webp"); */
  background-size: cover;
  background-position: center;
  position: relative;
  min-height: 600px;
}
.home-newvideo-box {
  -webkit-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  min-height: 600px;
}
.home-vide-wrap .home-newvideo-box {
  min-height: 800px;
}
.home-vide-wrap.image-wrapper {
  padding: 0px;
}
.video-play-button {
  position: relative;
  z-index: 1;
  box-sizing: content-box;
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}
.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
  border: 2px solid #fba505;
  background: #fba505;
}
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.8);
    opacity: 0;
  }
}
.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  transition: all 200ms;
  border: 2px solid #fff;
  background: #fff;
}
.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 18px solid #000;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  top: 9px;
  left: 10px;
}
.revol-section .image-wrapper p {
  font-size: 30px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 0;
  color: #fff;
  text-align: center;
  margin-top: 23px;
  font-family: "Outfit", sans-serif;
}

.grapgh-section {
  height: 240px;
}

/* Book Ride */
.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.bRide-section {
  background: url("/public/assets/ride-download/ridebg.webp");
  background-size: cover;
  background-position: center;
  position: relative;
  margin: 30px 0px;
}

.bRide-section.bike-bride {
  background: url("/public/assets/bike-pooling/ridebg.webp");
  background-size: cover;
  background-position: center;
}

.bRide-section .top-img img {
  width: 76%;
  margin: 0 auto;
}
.bRide-section .bottom-img {
  position: absolute;
  z-index: 0;
  top: 24%;
  left: -9%;
  width: 31%;
}
.bRide-section .image {
  position: relative;
  text-align: right;
}
.bRide-section ul {
  display: flex;
  padding: 0px;
  margin: 0px;
  list-style: none;
  margin-top: 26px;
}
.bRide-section ul img {
  width: 140px;
  height: 41px;
  object-fit: contain;
}
.bRide-section .content h5 {
  color: #fff;
  font-family: "Outfit", sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.2;
}
.bRide-section .content p {
  color: #fff;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: 0.4px;
}

/* testimonial */
.testimonial-wrap .heading,
.map-section .heading {
  font-size: 30px;
}

.testimonial-wrap .testimonial-title p {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.2;
}

.testimonial-wrap .block-1 {
  position: relative;
  overflow: hidden;
  transition: 0.5s;
  margin-right: 20px;
  cursor: pointer;
}

.news-img {
  margin-bottom: 0;
  overflow: hidden;
  transition: transform 2s ease 0s;
  background-color: #ffffffb5;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
}
.home-video .video-container {
  height: 100%;
  width: 100%;
}

.news-inner:hover .news-img {
  opacity: 1;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);

  visibility: visible;
}

.news-inner:hover .block-1-content-1 {
  opacity: 0;
  transition: all 0.7s ease 0s;
}

.testimonial-wrap .news-inner {
  overflow: hidden;
  position: relative;
  transition: -webkit-transform 2s ease 0s;
  transition: transform 2s ease 0s;
  transition: transform 2s ease 0s, -webkit-transform 2s ease 0s;
}
.testimonial-wrap .block-1-content-1 img {
  width: 42px;
  margin: 0 auto;
  margin-bottom: 14px;
  min-height: 47px;
}
.testimonial-wrap .block-1-content-1 {
  background: url("/public/assets/testimonial/mask-overlay.webp");
  background-repeat: no-repeat;
  border: 1px solid rgb(0 0 0 / 30%);
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  object-fit: cover;
  min-height: 207px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
}

.testimonial-wrap p {
  text-align: center;
  color: #000;
  font-family: "Outfit", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.3;
  text-transform: capitalize;
  margin-bottom: 0px;
}
/* .testimonial-wrap .block-1:hover .block-1-content {
    opacity: 1;
    max-height: 40rem;
    top: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    } */
.slick-arrow {
  background: #000;
  width: 40px;
  height: 40px;
  border: 1px solid #000;
  z-index: 1;
  transform: translatey(-50%);
  transition: 0.3s ease-out;
}

.slick-next:hover {
  margin-right: -5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.slick-prev:hover {
  margin-left: -5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.slick-prev {
  left: -54px;
}
.slick-next {
  right: -54px;
}
.slick-prev:before {
  content: "\f053";
}
.slick-next:before {
  content: "\f054";
}
.slick-prev:before,
.slick-next:before {
  opacity: 1;
  color: #fff;
  font-family: "FontAwesome";
  font-size: 13px;
}
.slick-arrow:hover,
.slick-arrow:focus {
  background: #fff;
}
.slick-arrow:hover:before,
.slick-arrow:focus:before {
  color: #000;
}
/* Blog */
.blog-inner h6 > a {
  color: #000;
  font-family: "Outfit", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.1;
}
.blog-inner:hover h6 > a {
  color: #fba505;
}
.blog-inner p {
  color: rgba(0, 0, 0, 0.7);
  min-height: 70px;
  margin-bottom: 0px;
}
.blog-inner .content {
  padding: 20px 0px;
}

.blog-inner a {
  color: hsl(0deg 0% 0% / 41%);
  font-family: "Outfit", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
  text-decoration: none;
}

.blog-section .view-wrap {
  text-align: right;
}
.blog-row .content {
  color: #000;
  font-family: "Outfit", sans-serif;
  font-size: 26px;
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
}
.blog-section .img {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.blog-section .img:before {
  content: "";
  background: linear-gradient(
    0deg,
    rgb(251 165 5 / 70%),
    rgba(0, 112, 186, 0.7)
  );
  height: 0;
  width: 0;
  z-index: 1;
  position: absolute;
  -webkit-transition-duration: 1.3s;
  -o-transition-duration: 1.3s;
  transition-duration: 1.3s;
  right: 0;
  opacity: 0.7;
  top: 0;
}
.blog-section .img img {
  transition: transform 0.5s ease-in-out;
}
.blog-section .img:hover img {
  transform: scale(1.08);
}
.blog-section .img:hover::after,
.blog-section .img:hover::before {
  height: 100%;
  opacity: 0;
  width: 100%;
}
.blog-section .img:after {
  content: "";
  background: linear-gradient(
    0deg,
    rgb(251 165 5 / 100%),
    rgba(0, 112, 186, 0.7)
  );
  height: 0;
  width: 0;
  z-index: 1;
  position: absolute;
  -webkit-transition-duration: 1.3s;
  -o-transition-duration: 1.3s;
  transition-duration: 1.3s;
  bottom: 0;
  opacity: 0.7;
  left: 0;
}
.blog-inner:hover a {
  color: #000;
}
.blog-inner a i {
  font-size: 12px;
  line-height: 1;
}

.theme-button {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: auto;
  padding: 0px;
}
.theme-button span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0px;
  top: 0;
  left: 0;
  width: 100%;
  padding: 9px 30px 9px 20px;
  transition: 0.3s;
  color: rgb(255, 255, 255);
  border: 1px solid #ff9b05;
  transition: 0.2s 0.1s;
  font-family: "Outfit", sans-serif;
  text-transform: capitalize;
  font-weight: 700;
}
.theme-button::before {
  background-color: #ff9b05;
  transition: 0.3s ease-out;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}

.theme-button::before,
.theme-button::after {
  position: absolute;
  content: "";
}
.theme-button:hover::before {
  height: 0%;
}
.chat-section .fa-chevron-right:before {
  vertical-align: sub;
}
.theme-button:after {
  content: "\f054";
  font-family: "FontAwesome";
  font-size: 10px;
  vertical-align: middle;
  position: absolute;
  right: 15px;
  top: 13px;
  color: #fff;
}
.theme-button span:hover {
  color: rgb(28, 31, 30);
  transition: 0.2s 0.1s;
}
.theme-button:hover:after {
  color: rgb(28, 31, 30);
}
.commute-section .theme-button2 {
  border: 1px solid #fff;
}
.commute-section .theme-button2 span {
  color: #fff;
}
.commute-section .theme-button2:hover span,
.commute-section .theme-button2:hover:after {
  color: #000;
}
.commute-section .theme-button2:after {
  color: #fff;
}
.commute-section .theme-button2::before {
  background-color: #fff;
}
.theme-button2 {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
  min-width: auto;
}
.theme-button2::before,
.theme-button2::after {
  position: absolute;
  content: "";
}
.theme-button2::before {
  background-color: #ff9b05;
  transition: 0.3s ease-out;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0%;
  width: 100%;
}
.theme-button2::after {
  position: absolute;
  content: "";
}
.theme-button2 span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0px;
  text-transform: uppercase;
  top: 0;
  left: 0;
  width: 100%;
  padding: 9px 28px 9px 20px;
  transition: 0.3s;
  color: rgb(28, 31, 30);
  border: 1px solid #ff9b05;
  transition: 0.2s;
  font-family: "Outfit", sans-serif;
  text-transform: capitalize;
  font-weight: 700;
}
.theme-button2:hover::before {
  height: 100%;
}
.theme-button2:hover span,
.theme-button2:hover:after {
  color: #fff;
}
.theme-button2:after {
  content: "\f054";
  font-family: "FontAwesome";
  font-size: 10px;
  vertical-align: middle;
  position: absolute;
  right: 13px;
  top: 14px;
  color: #000;
}

.slick-active .pickup-inner {
  transform: scale(1);
}
.slick-active .pickup-inner img {
  animation: grow 3s linear forwards;
  opacity: 1;
}

/* youtube */

.youtube-wrap .author-section img {
  width: 50px;
}
.youtube-section .slick-prev {
  left: -18px;
}
.youtube-section .image-wrapper {
  position: relative;
  background: url("/public/assets/youtube/youtube-img1.webp");
  background-size: cover;
  background-position: right;
  height: 300px;
  width: 100%;
  object-fit: cover;
  clip-path: polygon(0% 0%, 100% 0%, 93% 76%, 0% 1000%);
  margin: -10px;
}

.youtube-section .image-wrapper1 {
  background: url("/public/assets/youtube/testimonial-img1.webp");
  background-size: cover;
  background-position: center;
}

.youtube-section .image-wrapper2 {
  background: url("/public/assets/youtube/testimonial-img2.webp");
  background-size: cover;

  background-position: center;
}
.youtube-section .image-wrapper4 {
  background: url("/public/assets/youtube/testimonial-img4.webp");
  background-size: cover;
  background-position: center;
}
.youtube-section .image-wrapper5 {
  background: url("/public/assets/youtube/testimonial-img6.webp");
  background-size: cover;
  background-position: center;
}

.youtube-section .image-wrapper3 {
  background: url("/public/assets/youtube/testimonial-img3.webp");
  background-size: cover;
  background-position: center;
}
.youtube-inner .video-play-button {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  /* background: #fa183d; */
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}

.youtube-inner .video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
}

.youtube-inner .video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  transition: all 200ms;
}

.youtube-inner .video-play-button span {
  display: flex;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 15px solid #000;
  border-top: 8px solid transparent;
  border-bottom: 6px solid transparent;
  align-items: center;
  position: relative;
  justify-content: center;
  margin-top: 5px;
  margin-left: -4px;
}

.youtube-section .image {
  position: relative;
}
.youtube-section .slick-next {
  right: -18px;
}
.youtube-section h3 {
  margin-bottom: 18px;
  font-size: 23px;
  font-weight: 600;
  line-height: 1.2;
  text-transform: capitalize;
  font-family: "Outfit", sans-serif;
  color: #2f2f2f;
}
.youtube-section p {
  text-transform: capitalize;
  font-family: "Outfit", sans-serif;
  color: #2f2f2f;
  margin-bottom: 20px;
  line-height: 1.6;
  position: relative;
  display: inline-block;
}

.youtube-section p:before {
  background: url("/public/assets/youtube/left-quote.webp");
  background-size: cover;
  background-position: center;
  content: "";
  width: 27px;
  height: 18px;
  display: inline-block;
  position: absolute;
  left: -35px;
  top: -9px;
}

.youtube-section p:after {
  background: url("/public/assets/youtube/right-quote.webp");
  background-size: cover;
  background-position: center;
  content: "";
  width: 27px;
  height: 18px;
  display: inline-block;
  position: absolute;
  right: 50px;
  top: auto;
}
.author-content {
  margin-left: 10px;
}

.designation {
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.4px;
  font-family: "Outfit", sans-serif;
}

.youtube-inner .left {
  width: 40%;
  float: left;
}

.youtube-wrap {
  box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.07);
}
.youtube-inner .right {
  width: 60%;
  float: left;
}
.youtube-inner .content {
  padding: 40px;
  max-width: 574px;
  margin: 0 auto;
}

.author-section {
  display: flex;
  align-items: center;
}

.author-content h4 {
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.4;
  text-transform: capitalize;
  font-family: "Outfit", sans-serif;
  color: #2f2f2f;
}
.youtube-inner {
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin: 10px;
}

.youtube-inner .left > .image > img {
  height: 100%;
  min-height: 300px;
  object-fit: cover;
}

.react-datepicker-wrapper {
  width: 100%;
}

.banner-section a {
  text-decoration: none;
}
@keyframes grow {
  0%,
  20% {
    transform: scale(1);
  }

  75%,
  100% {
    transform: scale(1.15);
  }
}

/* map */

.map-icon {
  margin-left: 10px;
  width: 40px;
  height: 40px;
  transition: opacity 0.25s ease;
  cursor: pointer;
  position: absolute;
  background: #fff;
  padding: 16px;
  padding-bottom: 12px;
  top: 17%;
  border-radius: 50%;
  left: 7px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  z-index: 9;
}

.sidebar-section .aside-trigger {
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 1px;
  margin-bottom: 10px;
  display: block;
  text-align: right;
  cursor: pointer;
  position: relative;
  padding: 14px;
  padding-top: 20px;
  font-family: "Outfit", sans-serif;
}

.sidebar-section.sider-active {
  left: 0;
}
.map-icon span:nth-child(1) {
  top: 13px;
}

.map-icon span:nth-child(2) {
  top: 19.5px;
}

.map-icon span:nth-child(3) {
  top: 26px;
}

.map-img video {
  height: 100%;
  width: 100%;
}
.map-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 63%;
  background: #fba505;
  border-radius: 9px;
  opacity: 1;
  left: 6px;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.graph-area {
  position: relative;
}

.sidebar-section {
  position: fixed;
  top: 0;
  width: 200px;
  height: 100%;
  left: -200px;
  transition: 0.3s;
  z-index: 999;
  overflow-x: hidden;
  overflow-y: auto;
  background: #000;
}

.aside-trigger:before,
.aside-trigger:after {
  position: absolute;
  right: 25px;
  content: " ";
  height: 13px;
  width: 2px;
  background-color: #fff;
  top: 24px;
}

.aside-trigger span {
  margin-right: 30px;
}

.aside-trigger:after {
  transform: rotate(-45deg);
}
.aside-trigger:before {
  transform: rotate(45deg);
}

.sidebar-section .sidebar-item li a {
  color: #ff9b05;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0.4px;
  position: relative;
  text-decoration: none;
  font-family: "Outfit", sans-serif;
  display: inline-block;
}

.aside-trigger:hover:before,
.aside-trigger:hover:after {
  background: #ff9b05;
}
.sidebar-section .aside-trigger:hover {
  color: #ff9b05;
}
.sidebar-section .sidebar-item li a:hover {
  color: #fff;
}
/* map */

.banner-section .card-body {
  padding-top: 3px;
}

.glide__slide.glide__slide--active img {
  transition: 4s width ease;
  transition-delay: 4s;
}

/* Phone Number */

/* Bike Pooling
 */
.youtube-section .bike-wrapper1 {
  background: url("/public/assets/bike-pooling/testimonial-img1.webp");
  background-size: cover;
}

.youtube-section .bike-wrapper2 {
  background: url("/public/assets/bike-pooling/testimonial-img2.webp");
  background-size: cover;
}

.youtube-section .bike-wrapper3 {
  background: url("/public/assets/bike-pooling/testimonial-img3.webp");
  background-size: cover;
}

.youtube-section .bike-wrapper4 {
  background: url("/public/assets/bike-pooling/testimonial-img4.webp");
  background-size: cover;
}

.youtube-section .bike-wrapper5 {
  background: url("/public/assets/bike-pooling/testimonial-img5.webp");
  background-size: cover;
}

.google-inner {
  display: flex;
  margin-bottom: 10px;
}

.banner-slider .banner-btn {
  width: 90%;
  margin: 0 auto;
}

.banner-slider .banner-btn .theme-button:hover,
.banner-slider .banner-btn .theme-button span:hover {
  color: #fff;
}
.model-row {
  display: flex;
  align-items: center;
  background-size: cover;
  padding: 10px 14px;
}
.gif-model .gif-image {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 1;
}
.gif-model {
  /* background: url("/public/assets/header/bg-gif.gif"); */
  position: relative;
  background-size: cover;
  z-index: 11;
  background-position: center;
}

.model-form .valid-text {
  margin-top: -13px;
  color: #aeaeae;
}

.model-row p {
  color: #e06b03;
  text-align: right;
  margin-bottom: 7px;
}
.react-responsive-modal-closeButton {
  right: 5px !important;
  top: 0px !important;
  z-index: 99;
}
.react-responsive-modal-closeButton svg {
  fill: #e06b03;
  width: 20px !important;
}

.react-responsive-modal-modal {
  padding: 0px !important;
  margin-top: 50px !important;
  border-radius: 5px;
  border: 3px solid #e06b03;
}

.model-row .form-sec {
  position: relative;
  border: 2px dashed #f8a802de;
  padding: 5px;
  max-width: 200px;
  margin-left: auto;
  z-index: 2;
}

.model-form h5 {
  font-size: 17px;
  margin-bottom: 0px;
}
.model-form {
  /* border: 1.7px dashed #E06B03; */
  padding: 10px 20px 6px 20px;
  max-width: 640px;
  margin: 0 auto;
  border-top: 0px;
  margin-bottom: 13px;
}
.model-form ul {
  list-style: disc;
  padding-left: 20px;
}
.model-form p {
  margin-bottom: 2px;
  font-size: 14px;
}
.model-row .model-left,
.model-row .model-right {
  width: 50%;
}
.model-form .copy-text {
  position: relative;
}

.copy-text span {
  display: block;
  text-align: right;
  color: #e06b03;
  font-size: 14px;
}
.copy-text span {
  display: block;
  text-align: right;
}
.model-row .form-btn {
  border: 0px;
  background: transparent;
  position: absolute;
  right: 14px;
  top: 15px;
}
.model-row .form-control:focus {
  color: #e06b03;
  box-shadow: none;
}
.model-row .form-control {
  height: auto;
  padding: 10px 10px;
  color: #e06b03;
  padding-left: 47px;
}
.model-row .model-right {
  position: relative;
}

.model-row .gift-img {
  position: absolute;
  width: 60px;
  z-index: 1;
  max-width: 100%;
  left: 24%;
  top: 25%;
}

.copy-text button:active {
  background: #809ce2;
}
.copy-text button:before {
  content: "Copied";
  position: absolute;
  top: -48px;
  right: 0px;
  background: #e06b03;
  padding: 5px 11px;
  border-radius: 20px;
  font-size: 15px;
  display: none;
  color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border: 1px solid #fff;
}

.copy-text button:after {
  content: "";
  position: absolute;
  top: -20px;
  right: 25px;
  width: 10px;
  height: 10px;
  background: #e06b03;
  transform: rotate(45deg);
  display: none;
  color: #fff;
}
.copy-text.active button:before,
.copy-text.active button:after {
  display: inline-block;
}

.model-form p i {
  padding-left: 4px;
}
/* media query */
@media only screen and (min-width: 1400px) {
  .banner-section .card {
    min-height: 288px;
    padding-bottom: 9px;
  }

  .slick-prev {
    left: -48px;
  }

  .slick-next {
    right: -35px;
  }
  .riding-wrap img {
    width: 123px;
  }
  .banner-section .bottom-section li {
    min-height: 140px;
  }
  .banner-section .content {
    font-size: 19px;
  }
  .banner-section .card {
    padding-top: 19px;
  }
  .banner-section .form-group {
    margin-bottom: 25px;
  }

  .grapgh-section {
    height: 234px;
  }
  .banner-section .offer-btn {
    margin-top: 83px;
  }

  .banner-section .card {
    margin-top: -57px;
  }
}

@media only screen and (min-width: 1600px) {
  .banner-section .card {
    min-height: 260px;
    padding-bottom: 1px;
  }
  .banner-section .banner-content h1 {
    font-size: 50px;
  }
  .banner-section .banner-content p {
    font-size: 37px;
  }

  .banner-section .qr-image img {
    width: 210px;
  }

  .banner-section .google-img img {
    width: 210px;
  }
  .banner-section .content {
    font-size: 20px;
  }
  .banner-section .form-control,
  .banner-section .form-select,
  .banner-section .nav-tabs .nav-link {
    font-size: 16px;
  }

  .banner-section .nav-tabs .nav-link {
    padding: 17px 43px;
  }
  .banner-section .form-control {
    padding: 26px 20px;
  }
  .banner-section .form-group {
    margin-bottom: 21px;
  }
  .banner-section .card {
    padding-top: 8px;
  }

  .banner-section .bottom-section li {
    min-height: 126px;
  }
  .grapgh-section {
    height: 281px;
  }

  .banner-section .offer-btn {
    margin-top: 80px;
  }
}

@media only screen and (min-width: 1700px) {
  .banner-section .bottom-section li {
    min-height: 137px;
  }
  .riding-wrap img {
    width: 123px;
  }
  .banner-section .card {
    min-height: 300px;
  }
  .banner-section .form-group {
    margin-bottom: 24px;
  }
  .banner-section .content {
    font-size: 23px;
  }
  .banner-section .form-control,
  .banner-section .form-select,
  .banner-section .nav-tabs .nav-link {
    font-size: 15px;
  }
  .grapgh-section {
    height: 317px;
  }
  .banner-section .offer-btn {
    margin-top: 90px;
  }
}
@media only screen and (min-width: 2000px) {
  .grapgh-section {
    height: 552px;
  }
  .banner-section .offer-btn {
    margin-top: 91px;
  }
}

@media only screen and (max-width: 1199px) {
  .banner-section .nav-tabs .nav-link {
    padding: 13px 37px;
  }
  .youtube-section .slick-next {
    right: 0px;
  }
  .youtube-section .slick-prev {
    left: 0%;
  }
  .banner-section .banner-content {
    bottom: 12%;
  }
  .banner-section .nav-item:last-child {
    margin-left: -23px;
  }
  .youtube-section .content p:before {
    left: -33px;
    top: -15px;
  }
  .youtube-section .content p {
    margin-left: 41px;
  }
  .revol-section .image-wrapper,
  .home-newvideo-box {
    min-height: 400px;
  }
  .slick-prev {
    left: -26px;
  }
  .slick-next {
    right: -26px;
  }
  .banner-section .qr-image img {
    width: 120px;
  }

  .banner-section .banner-content h1 {
    font-size: 37px;
  }
  .home-banner.banner-section .banner-content {
    bottom: 5%;
  }
}
@media only screen and (max-width: 1050px) {
  .banner-section .nav-tabs .nav-link {
    padding: 13px 29px;
  }
}
@media only screen and (max-width: 1020px) {
  .banner-section .nav-item:last-child {
    margin-left: 0;
  }
  .banner-section .nav-tabs .nav-link {
    padding: 13px 20px;
  }
  .banner-section .banner-content h1 {
    font-size: 35px;
  }
  .banner-section .banner-content p {
    font-size: 25px;
  }
}
@media only screen and (max-width: 993px) {
  .home-vide-wrap .home-newvideo-box {
    min-height: 700px;
  }
}

@media only screen and (max-width: 1024px) {
  .testimonial-wrap .heading,
  .map-section .heading {
    font-size: 25px;
  }
  .heading {
    font-size: 29px;
  }

  .testimonial-wrap p {
    font-size: 17px;
  }
  .commute-section .content h5,
  .commute-section .content h5 a {
    font-size: 25px;
  }

  .commute-section .heading {
    font-size: 24px;
  }
  .ride-inner-wrap h3,
  .bRide-section .content h5,
  .revol-section .image-wrapper p {
    font-size: 21px;
  }
  .testimonial-wrap .block-1-content-1 {
    top: 57%;
  }
  .commute-section .content h5 {
    font-size: 24px;
  }
  .glide__slide img {
    width: 60%;
  }
  .glide-section .heading {
    font-size: 24px;
  }

  .glide__bullet span {
    font-size: 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1130px) {
  .banner-section .bottom-section li {
    min-height: 135px;
  }
  .banner-section .nav-tabs .nav-link {
    padding: 13px 28px;
  }
  .banner-section .card {
    padding-bottom: 11px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1047px) {
  .banner-section .banner-content {
    width: 100%;
  }
  .banner-section .banner-content {
    bottom: 10%;
    padding: 0 10px;
  }
  .banner-section .nav-tabs .nav-link {
    padding: 13px 13px;
  }
  .banner-section .banner-content h1 {
    font-size: 32px;
  }
  .banner-section .banner-content p {
    font-size: 23px;
  }
  .slick-prev {
    left: 0px;
  }
  .slick-next {
    right: 0px;
  }
  .youtube-section .content h3 {
    font-size: 21px;
    margin-bottom: 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 990px) {
  .banner-section .banner-right,
  .banner-section .banner-left {
    width: 100%;
    float: none;
  }

  .youtube-section .content p:before,
  .youtube-section .content p:after {
    width: 28px;
    height: 19px;
    background-size: cover;
  }
  .youtube-section .content p:after {
    bottom: 1px;
    right: -23px;
  }
}
@media only screen and (max-width: 992px) {
  .commute-section .commute-col {
    width: 100%;
    float: none;
    margin-bottom: 20px;
  }
  .commute-section .commute-col:last-child {
    margin-bottom: 0px;
  }
  .revol-section .image-wrapper {
    background-position: initial;
    min-height: auto;
    background-repeat: no-repeat;
  }
  .youtube-section .slick-next {
    right: -10px;
  }
  .bRide-section .top-img img {
    width: 100%;
  }
  .bRide-section .bottom-img {
    top: 38%;
    left: -13%;
    width: 38%;
  }
  .slick-next {
    right: -10px;
  }
  .slick-prev {
    left: -10px;
  }
  .blog-section .view-wrap {
    text-align: right;
    margin-top: 15px;
  }
  .youtube-section .slick-prev {
    left: 0%;
  }

  .youtube-inner .content {
    padding: 17px;
  }
  .youtube-section p {
    font-size: 13px;
  }
  .youtube-section p br {
    display: none;
  }

  .banner-section .slider-col img {
    min-height: auto;
  }
  .bRide-section .content h5 br {
    display: none;
  }
}
@media only screen and (max-width: 769px) {
  .banner-section .banner-left,
  .banner-section .banner-right {
    width: 100%;
    float: none;
  }
  .banner-section .banner-left {
    padding-bottom: 20px;
  }
  .banner-section .slider-col img {
    min-height: auto;
  }
  .commute-section .content h5 {
    font-size: 22px;
  }
  .home-newvideo-box {
    min-height: 300px;
  }

  .home-vide-wrap .home-newvideo-box {
    min-height: 300px;
  }
  .ride-inner-wrap h3,
  .bRide-section .content h5,
  .revol-section .image-wrapper p {
    font-size: 19px;
  }
  .heading {
    font-size: 24px;
  }
  .heading br,
  .bRide-section .content h5 br,
  .ride-inner-wrap br {
    display: none;
  }
  .testimonial-wrap .heading,
  .map-section .heading {
    font-size: 26px;
  }
  .youtube-section .content h3 {
    font-size: 25px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .bRide-section .image {
    margin-top: 20px;
  }
  .video-play-button:after,
  .video-play-button:before {
    border: 1px solid #fba505;
    width: 60px;
    height: 60px;
  }
  .WhyChoose-section {
    padding-bottom: 0px;
  }

  .youtube-inner .left,
  .youtube-inner .right {
    width: 100%;
    float: none;
  }
  .youtube-section .content p {
    margin-left: 30px;
  }
  .youtube-section .image-wrapper {
    clip-path: unset;
  }
  .youtube-inner,
  .youtube-section .image-wrapper {
    flex-wrap: wrap;
    margin: 0px;
  }
  .glide__bullets,
  .glide__track {
    width: 100%;
    float: none;
  }
  .home-banner.banner-section .google-img {
    padding-top: 30px;
  }
  .glide__slides {
    white-space: nowrap;
  }
  .glide__slide img {
    width: 40%;
  }
  .home-banner.banner-section .banner-content h1 {
    font-size: 30px;
  }
  .pickup-section.section {
    padding: 30px 0px;
    padding-bottom: 0px;
  }
  .glide__bullet p {
    display: none;
    font-size: 13px;
    margin-bottom: 0px;
  }
  .glide__bullet--active p {
    display: block;
  }
  .glide__bullet {
    margin-bottom: 10px;
  }
  .glide__bullet span {
    font-size: 16px;
  }
  .glide__bullet:before {
    top: 20px;
  }
  .glide__bullets {
    padding-bottom: 20px;
  }
  .glide-section.section {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .glide-section .glide {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .banner-section .banner-content p {
    font-size: 17px;
  }
  .banner-section .banner-content h1 {
    font-size: 30px;
  }

  .ride-section .ride-left,
  .ride-section .ride-right {
    width: 100%;
    float: none;
  }
  .ride-section .ride-inner {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .ride-inner-wrap {
    padding: 30px;
  }
  .blog-section .view-wrap {
    text-align: left;
    margin-top: 15px;
  }

  .youtube-section .content p {
    margin-bottom: 20px;
    font-size: 13px;
  }
  .pickup-section .heading h2 {
    font-size: 26px;
  }
  .banner-section .qr-image img {
    width: 78px;
  }
  .bRide-section .bottom-img {
    top: 38%;
    left: -27px;
    width: 27%;
  }
  .youtube-section .slick-prev {
    left: 0%;
  }
  .banner-section .nav-item:last-child {
    margin-left: -23px;
  }
  .banner-section .banner-content {
    bottom: 19%;
  }
  .banner-section .banner-content h1 {
    font-size: 26px;
  }

  .youtube-section .content p:before,
  .youtube-section .content p:after {
    width: 25px;
    height: 17px;
    background-size: cover;
    right: 0;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 11px;
  }
  .slick-arrow {
    width: 35px;
    height: 35px;
  }
  .commute-section .content p {
    font-size: 14px;
  }
  .revol-section .image-wrapper p {
    font-size: 20px;
    margin-top: 10px;
  }
  .blog-inner h6 > a {
    font-size: 16px;
    line-height: 1.1;
  }
  .testimonial-wrap p {
    font-size: 17px;
  }
  .youtube-section .heading {
    font-size: 20px;
  }
  .commute-section .heading {
    font-size: 19px;
  }
  .section {
    padding: 30px 0px;
  }
  .commute-section .content h5,
  .commute-section .content h5 a {
    font-size: 20px;
  }

  .youtube-section {
    padding-top: 0px;
  }
  .commute-section {
    padding-top: 0px;
  }
  .blog-row .content {
    font-size: 21px;
  }

  .glide__bullet.glide__bullet--active span {
    font-size: 21px;
  }
  .home-banner.banner-section .banner-content h1 {
    font-size: 27px;
  }
  .home-banner.banner-section .google-img {
    padding-top: 30px;
  }
  .banner-section {
    min-height: auto;
  }
  .banner-section .icon {
    width: 80px;
    height: 63px;
  }

  .model-form {
    border: 0px dashed #e06b03;
    max-width: 100%;
    padding: 30px 30px 0px;
  }
  .popup-social .footer-social {
    margin-top: 10px;
    margin-bottom: 27px;
  }

  .banner-section .theme-button span {
    border: 1px solid #fba505;
    padding: 9px;
  }
  .banner-slider .banner-btn {
    width: 100%;
  }
  .banner-section .theme-button span {
    font-size: 10px;
    padding: 9px 10px 9px 10px;
  }

  .google-inner {
    margin-bottom: 5px;
  }

  .model-row .model-left,
  .model-row .model-right {
    text-align: center;
    width: 100%;
  }

  .model-row {
    flex-wrap: wrap;
    padding-bottom: 20px;
  }
  .model-right-inner {
    max-width: 400px;
    margin: 0 auto;
    padding-top: 20px;
  }

  .model-row .form-control {
    padding: 6px 10px;
    padding-left: 30px;
  }
  .model-row .gift-img {
    left: 8%;
    top: 44px;
    width: 50px;
  }

  .model-form p {
    text-align: justify;
  }

  .model-row .form-sec {
    max-width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .model-row .gift-img {
    left: -17px;
  }

  .model-row .form-sec {
    max-width: 100%;
  }
  .chosse-col {
    margin-right: 0px;
  }

  .testimonial-wrap .block-1-content-1 {
    min-height: 209px;
  }
  .youtube-section .content h3 {
    font-size: 17px;
  }
  .video-play-button:after,
  .video-play-button:before {
    width: 55px;
    height: 55px;
  }
  .banner-section .bottom-section li {
    width: 100%;
    float: none;
  }
  .ride-inner-wrap {
    padding: 20px;
  }

  .bRide-section {
    padding: 50px 0px;
  }
  .bRide-section .bottom-img {
    left: -11px;
  }
  .testimonial-wrap .heading,
  .map-section .heading {
    font-size: 20px;
  }
  .commute-image,
  .commute-content {
    width: 100%;
    float: none;
  }
  .commute-inner {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .youtube-section .slick-prev {
    left: -10px;
  }

  .banner-section .form-control {
    font-size: 12px;
  }
  .youtube-inner img {
    width: 100%;
  }
  .designation {
    font-size: 12px;
  }
  .slider-col img {
    object-fit: cover;
    height: 100%;
    min-height: auto;
  }
  .banner-section .banner-content h1 {
    line-height: 1;
  }
  .banner-section .banner-content {
    bottom: 3%;
    width: 100%;
    padding: 0px 20px;
    justify-content: end;
  }
  .map-section {
    padding-bottom: 0px;
  }
  .banner-section .nav-tabs .nav-link {
    padding: 13px 16px;
    font-size: 12px;
  }
  .banner-section .tab-content:before {
    height: 19%;
  }
  .chat-section span {
    font-size: 14px;
  }
  .chat-section {
    width: 156px;
    height: 62px;
    top: 32%;
    right: -66px;
  }
  .testimonial-wrap .block-1 {
    margin-right: 0px;
  }
  .glide__slide img {
    width: 66%;
  }
  .banner-section .banner-content h1 {
    font-size: 18px;
    text-align: left;
    margin-bottom: 0px;
  }
  .banner-section .banner-content p {
    font-size: 17px;
  }
  .banner-section .google-img {
    padding-top: 10px;
  }
  .home-banner.banner-section .banner-content h1 {
    font-size: 18px;
  }
  .home-banner.banner-section .google-img {
    padding-top: 20px;
  }
  .home-banner.banner-section .banner-content h1 br {
    display: none;
  }
  .model-row h1 {
    font-size: 21px;
    padding-top: 20px;
  }
  .model-form {
    padding: 30px 13px 0px;
    padding-top: 0px;
  }
}

@media only screen and (max-width: 380px) {
  .home-banner.banner-section .google-img {
    padding-top: 5px;
  }
}
